.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 150px;
  padding-top: 50px;
}

.description {
  color: gray !important;
}

.loginBtn {
  background-color: #222;
  border-radius: 10px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  line-height: 1.5;
  min-height: 50px;
  padding: 9px 20px 8px;
  width: 350px;
  margin-bottom: 10px;
}

.loginBtn svg {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 8px;
}

.loginBtn text {
  display: inline-block;
  vertical-align: middle;
}

.loginBtn:hover {
  background-color: #333;
  transition: background-color 0.5s ease;
}

.loginBtn:disabled,
.loginBtn[disabled] {
  background-color: #111;
  cursor: not-allowed;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.subscriptionBtn {
  background: rgb(255, 55, 95);
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
}
.subscriptionBtn:hover {
  filter: brightness(125%);
  transition: filter 0.5s ease;
}
.dashboardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  padding: 16px;
}
@media (max-width: 960px) {
  .dashboardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .listenContents span {
    font-size: 1rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  h4 {
    font-size: 1.25rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
  .discord-switch-box {
    margin: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .listenContents span {
    font-size: 0.9rem !important;
  }
}

.listeningContainer {
  display: inline-flex;
  justify-content: flex-start;
}

.listeningContainer .listenContents {
  flex-grow: 2;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  margin: auto auto auto 1.5em;
}

.discord-switch-box {
  background-color: #444ebc;
  margin: auto 0 auto auto;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}

.discord-switch-box .switch {
  bottom: 1px;
}
